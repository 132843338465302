<template>
    <div class="login">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <h1 class="custom-h1">
                        <span class="custom-color-primary">HOL</span
                        ><span class="custom-color-secondary">EFFECT</span>
                    </h1>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-12">
                    <h2 class="custom-color-primary custom-h2">
                        Mot de passe oublié
                    </h2>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6"></div>
                <div class="col-lg-2 text-right">
                    <p class="custom-color-secondary">*Champs requis</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Email*"
                        class="custom-form-input"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Password*"
                        class="custom-form-input"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4"></div>
                <div class="col-lg-2 text-left">
                    <p
                        class="custom-color-secondary element-pointer"
                        v-on:click="goRegister"
                    >
                        Créer un compte
                    </p>
                </div>
                <div class="col-lg-2 text-right">
                    <p
                        class="custom-color-secondary element-pointer"
                        v-on:click="goResetPassword"
                    >
                        Mot de passe oublié ?
                    </p>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row">
                <div class="col-lg-5"></div>
                <div class="col-lg-2">
                    <hr class="custom-hr" />
                </div>
                <div class="col-lg-5"></div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-button class="custom-btn-color">Se connecter</b-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        goRegister: function () {
            this.$router.push({ name: "Register" });
        },
        goResetPassword: function () {
            this.$router.push({ name: "ResetPassword" });
        },
    },
};
</script>
<style scoped>
.custom-h1 {
    font-size: 80px;
}
.custom-h2 {
    font-size: 50px;
}
.custom-hr {
    border: 3px solid #7ab92a;
}
.custom-btn-color {
    background-color: #0e476b;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
</style>
